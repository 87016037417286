<template>
 <div>
   <a-modal title="填写信息" v-model="visible" :width="600" @ok="onConfirm" okText="确定" @cancel="onCancel" cancelText="取消" :maskClosable="false">
     <a-form-model ref="form" :model="rowData" :rules="formRule" :label-col="{span:6}" :wrapper-col="{span:18}">
       <a-form-model-item label="单位名称" prop="taxCompany">
         <a-input v-model="rowData.taxCompany"></a-input>
       </a-form-model-item>
       <a-form-model-item label="纳税人识别码" prop="taxNum">
         <a-input v-model="rowData.taxNum"></a-input>
       </a-form-model-item>
       <a-form-model-item label="注册地址" prop="">
         <a-input v-model="rowData.taxComAddr"></a-input>
       </a-form-model-item>
       <a-form-model-item label="注册电话" prop="">
         <a-input v-model="rowData.taxComPhone"></a-input>
       </a-form-model-item>
       <a-form-model-item label="开户银行" prop="">
         <a-input v-model="rowData.taxBankName"></a-input>
       </a-form-model-item>
       <a-form-model-item label="银行账号" prop="">
         <a-input v-model="rowData.taxBankCardNum"></a-input>
       </a-form-model-item>
     </a-form-model>

   </a-modal>
 </div>
</template>

<script>
import {addOrderInfo, editOrderInfo} from '@/views/order_info/api/OrderInfoApi';

export default {
  data() {
    return {
      visible: false,
      rowData: {},
      formRule: {
        taxCompany: [
          {required: true, message: '请输入单位名称', trigger: 'blur'}
        ],
        taxNum: [
          {required: true, message: '请输入纳税人识别号', trigger: 'blur'}
        ],
      }
    }
  },
  created() {},
  methods: {
    isShow() {
      this.visible = true
    },
    onCancel() {
      this.visible = false
      this.$emit('get-invoice', {}, 'cancel')
    },
    onConfirm() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        this.$emit('get-invoice', this.rowData, 'confirm')
        this.visible = false
      })
    },
  }
 }
</script>

<style scoped>

</style>